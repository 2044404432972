<template>
  <v-card>
    <v-card-text class="text-no-wrap text--primary mt-3">
      <p class="font-weight-semibold text-h5 mb-1">
        {{ statTitle }}
      </p>
      <p class="text-h4 primary--text mb-0" style="cursor: pointer;"
        @click="$emit('click', { statTitle, subtitle, footNote })"
      >
        {{ subtitle }}
      </p>
      <p class="text-subtitle-1 grey--text mb-0">
        {{ total }}
      </p>
      <p class="text-caption grey--text mb-0">
        {{ footNote }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js';

export default {
  props: {
    statTitle: { type: String, default: '' },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    footNote: { type: String, default: '' },
    change: { type: String, default: '' },
    total: { type: String, default: '' },
  },
  setup() {
    const checkChange = (value) => {
      const firstChar = value.charAt(0);
      if (firstChar === '+') {
        return true;
      }

      return false;
    };

    return {
      mdiDotsVertical,
      checkChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.percentage {
  top: -8px;
  position: relative;
}
</style>
