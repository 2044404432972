<template>
  <v-row dense class="mb-2">
    <v-col cols="6" md="4" lg="2" v-for="delivery in deliveryDays" :key="delivery.day">
      <StatisticsCard
        :statTitle="delivery.day"
        :subtitle="delivery.deliveries.toString()"
        :foot-note="delivery.date"
        :total="`Estimated: R${delivery.cartTotal.toFixed(0)}`"
        @click="$emit('click', delivery.date)"
      />
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';
import StatisticsCard from './StatisticsCard.vue';

export default {
  name: 'AdminOrderSummary',
  components: {
    StatisticsCard,
  },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    daysOfWeek() {
      return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    },
    deliveryDays() {
      return this.daysOfWeek
        .filter((d) => d !== 'Sunday')
        .map((day) => {
          const deliveries = this.nextWeekDeliveries.find((d) => d.day === day);
          return {
            day,
            date: deliveries.date,
            deliveries: deliveries.deliveries,
            cartTotal: deliveries.total,
          };
        });
    },
    nextWeekDeliveries() {
      const weekOrders = Array(7).fill(0).map((_, i) => {
        const day = dayjs().add(i + 1, 'day');
        return this.orders.filter((order) => dayjs(order.deliveryDate).isSame(day, 'day'));
      });

      const { daysOfWeek } = this;
      const today = dayjs().day();

      return weekOrders.map((orders, i) => ({
        day: daysOfWeek[(today + i + 1) % 7],
        date: dayjs().add(i + 1, 'day').format('YYYY-MM-DD'),
        deliveries: orders.length,
        total: this.getCartTotal(orders),
      }));
    },
  },
  methods: {
    getCartTotal(orders) {
      // return if orders is not an array
      if (!Array.isArray(orders)) return 0;
      const carts = orders?.map((order) => order.cart) ?? [];
      const cartTotals = carts.map((cart) => cart.reduce((acc, item) => acc + item.total, 0));
      return cartTotals.reduce((acc, total) => acc + total, 0);
    },
  },
};
</script>
