<template>
  <v-app :class="{ ios: isIos }">
    <v-main>
        <v-container class="fill-height" fluid>
          <v-row class="fill-height" align="center" justify="center">
            <v-col class="fill-height d-flex flex-column align-stretch" cols="12" md="4">
              <v-img
                class="slide-from-top mb-5"
                src="../assets/Logo-Wide.svg" style="max-height: 100px; max-width: 100%;"
                contain alt="logo"
              />
              <transition name="fade">
                <router-view/>
              </transition>
            </v-col>
          </v-row>
        </v-container>
    </v-main>
  </v-app>
</template>

<script>
import '../site.scss';
import platform from '../mixins/platform';

export default {
  name: 'Blank',
  mixins: [platform],
};
</script>
